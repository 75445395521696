import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'
import { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

interface PickerProps {
  text: string
  value: number
  disableIncrement?: boolean
  disableDecrement?: boolean
  error?: boolean
  min?: number
  max?: number
  onChange: (val: number) => void
  increaseAriaLabel?: string
  decreaseAriaLabel?: string
  rounded?: boolean
  className?: string
  style?: object
  themeStyle?: PartialBookingEngine['pickerField']['picker']
  onDisabledIncrementClick?: (val: number) => void
  iconColor?: string
}

interface ButtonProps {
  isDisabled: boolean
}

interface ContainerProps {
  className?: string
  themeStyle?: PartialBookingEngine['pickerField']['picker']
}

interface SharedProps {
  isRounded: boolean
  themeStyle?: PartialBookingEngine['pickerField']['picker']
}

interface TextProps {
  themeStyle?: PartialBookingEngine['pickerField']['picker']
}

const commonButtonStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;

  &:focus:not(:disabled) {
    outline: 1px solid ${colors.fountainBlue};
  }
`

const styles = {
  IconContainer: styled.div`
    transition: transform 200ms ease;
  `,
  Container: styled.div<ContainerProps & SharedProps>`
    display: flex;
    width: 100%;
    border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;
    background: ${props => props?.themeStyle?.backgroundColor};

    ${({ isRounded, themeStyle }) =>
      !isRounded &&
      css`
        background: ${themeStyle?.backgroundColor ?? colors.white};
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      `}
  `,
  TextWrap: styled.div<SharedProps>`
    display: flex;
    align-items: center;
    flex: 1 0;
    ${({ isRounded }) =>
      isRounded
        ? css`
            padding-right: ${({ theme }) => theme.spacing[2]};
          `
        : css`
            padding-left: ${({ theme }) => theme.spacing[2]};
          `}
  `,
  Text: styled.p<TextProps>`
    font-size: 16px;
    font-weight: normal;
    color: ${props => props?.themeStyle?.textColor ?? 'inherit'};
  `,
  Label: styled(Type)<SharedProps>`
    ${({ isRounded }) =>
      isRounded &&
      css`
        font-size: 14px;
      `}
  `,
  ButtonWrap: styled.div<SharedProps>`
    display: flex;
    ${({ isRounded, themeStyle }) =>
      isRounded &&
      css`
        border: 1px solid ${themeStyle?.borderColor ?? colors.formGrey};
        height: 32px;
      `}
  `,
  Button: styled.button<ButtonProps & SharedProps>`
    ${commonButtonStyles}
    cursor: pointer;
    > svg {
      opacity: ${({ isDisabled }) => (isDisabled ? 0.33 : 1)};
    }

    ${({ isRounded, themeStyle }) =>
      isRounded
        ? css`
            height: 30px;
            width: 30px;
          `
        : css`
            height: 50px;
            width: 50px;
            border-left: 1px solid ${themeStyle?.borderColor ?? colors.formGrey};

            ${media.md(css`
              height: 60px;
              width: 60px;
            `)}
          `}
  `,
  CountWrap: styled.div<SharedProps>`
    align-items: center;
    display: flex;
    height: 50px;
    width: 50px;
    justify-content: center;
    border-left: 1px solid
      ${props => props?.themeStyle?.borderColor ?? colors.formGrey};

    > ${Type} {
      user-select: none;
    }

    ${media.md(css`
      height: 60px;
      width: 60px;
    `)}

    ${({ isRounded, themeStyle }) =>
      isRounded &&
      css`
        height: 30px;
        width: 30px;
        border-left: 1px solid ${themeStyle?.borderColor ?? colors.formGrey};
        border-right: 1px solid ${themeStyle?.borderColor ?? colors.formGrey};

        ${media.md(css`
          height: 30px;
          width: 30px;
        `)}
      `}
  `,
}

const MinusIcon = ({ color = colors.black }) => (
  <svg
    width="14"
    height="3"
    viewBox="0 0 14 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.7523 2.32004V0.81604H0.696289V2.32004H13.7523Z" fill={color} />
  </svg>
)

const PlusIcon = ({ color = colors.black }) => (
  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.752 7.32V5.816h-5.76V.056H6.488v5.76H.696V7.32h5.792v5.792h1.504V7.32h5.76z"
      fill={color}
    />
  </svg>
)

const MinusIconRounded = ({ color = colors.midGrey }) => (
  <svg
    width="12"
    height="1"
    viewBox="0 0 12 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.5"
      y1="0.5"
      x2="11.5"
      y2="0.5"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
)

const PlusIconRounded = ({ color = colors.midGrey }) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.5"
      y1="5.5"
      x2="10.5"
      y2="5.5"
      stroke={color}
      strokeLinecap="round"
    />
    <line
      x1="5.5"
      y1="0.5"
      x2="5.5"
      y2="10.5"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
)

export const Picker: FC<PickerProps> = ({
  text,
  value = 0,
  min = 0,
  disableIncrement,
  disableDecrement,
  error = false,
  max,
  onChange,
  increaseAriaLabel,
  decreaseAriaLabel,
  rounded,
  className,
  themeStyle,
  style,
  iconColor,
  onDisabledIncrementClick,
}) => {
  const incrementDisabled = disableIncrement || (max != null && value >= max)
  const decrementDisabled = disableDecrement || value <= min

  const _onClickIncrement = () => {
    if (incrementDisabled) {
      if (onDisabledIncrementClick) {
        onDisabledIncrementClick(value)
      }
      return
    }
    return onChange(value + 1)
  }

  const _onClickDecrement = () => {
    if (decrementDisabled) {
      return
    }
    return onChange(value - 1)
  }

  const increaseAria = increaseAriaLabel || `increase number of ${text}`
  const decreaseAria = decreaseAriaLabel || `decrease number of ${text}`
  return (
    <styles.Container
      style={{
        outline: error ? `1px solid ${colors.errorRed}` : 'none',
        ...style,
      }}
      isRounded={rounded}
      className={className}
      themeStyle={themeStyle}
    >
      <styles.TextWrap isRounded={rounded}>
        <styles.Label
          preset="label"
          isRounded={rounded}
          color={themeStyle?.textColor ?? 'inherit'}
        >
          {text}
        </styles.Label>
      </styles.TextWrap>
      <styles.ButtonWrap isRounded={rounded} themeStyle={themeStyle}>
        <styles.Button
          aria-label={decreaseAria}
          onClick={_onClickDecrement}
          isDisabled={decrementDisabled}
          disabled={decrementDisabled}
          data-test="picker-decrement"
          isRounded={rounded}
          type="button"
          themeStyle={themeStyle}
        >
          {rounded ? (
            <MinusIconRounded color={iconColor ?? themeStyle?.iconColor} />
          ) : (
            <MinusIcon color={iconColor ?? themeStyle?.iconColor} />
          )}
        </styles.Button>
        <styles.CountWrap isRounded={rounded} themeStyle={themeStyle}>
          <styles.Text
            data-test="picker-value"
            aria-live="polite"
            themeStyle={themeStyle}
          >
            {value}
          </styles.Text>
        </styles.CountWrap>
        <styles.Button
          aria-label={increaseAria}
          onClick={_onClickIncrement}
          isDisabled={incrementDisabled}
          data-test="picker-increment"
          isRounded={rounded}
          type="button"
          themeStyle={themeStyle}
        >
          {rounded ? (
            <PlusIconRounded color={iconColor ?? themeStyle?.iconColor} />
          ) : (
            <PlusIcon color={iconColor ?? themeStyle?.iconColor} />
          )}
        </styles.Button>
      </styles.ButtonWrap>
    </styles.Container>
  )
}
