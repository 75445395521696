import React from 'react'
import styled, { css } from 'styled-components'

import { HelpfulLinks } from '../../elements/HelpfulLinks'
import { Type } from '../../elements/Typography/Typography'
import { media } from '../../utils/media'

const Container = styled.div``
const Content = styled.article`
  padding-bottom: 32px;

  ${media.md(css`
    padding-bottom: ${({ theme }) => theme.spacing[4]};
  `)};
`

const FormSuccess = ({
  formSuccess: {
    fields: { heading, multilineSubheading, links },
  },
}) => (
  <Container>
    <Content>
      <Type as="h1" size={{ xs: 24, md: 30 }} bottom={{ xs: 0.5, md: 1 }}>
        {heading}
      </Type>
      {multilineSubheading && (
        <Type
          as="h2"
          size={{ xs: 18, md: 18 }}
          weight="light"
          multiline
          lineHeight={2}
        >
          {multilineSubheading}
        </Type>
      )}
    </Content>
    {links && (
      <HelpfulLinks title={links.fields.title} links={links.fields.links} />
    )}
  </Container>
)

export default FormSuccess
