import React from 'react'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  color?: string
}

export const ChevronRight = ({ color = 'black', ...rest }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 8 14"
      height="12"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1l6 6-6 6"
      />
    </svg>
  )
}
