import lodash from 'lodash'
import get from 'lodash/get'

import { calcPrice } from '../currency/calcPrice'
import { HB_PRODUCT_IDS, PRODUCT_IDS } from '../ProductIds'
import { triggerEvent } from './events'
import { ViewItemList } from './types/google-analytics-events'

interface ProductReference {
  fields: {
    productId: string
    name: string
    brand: string
    category: string
    list: string
    price?: {
      fields: {
        isk?: number
        title: string
        priceFormat?: string
      }
    }
  }
}

export const productIdToName = {
  [PRODUCT_IDS.SpaComfort]: 'Comfort',
  [PRODUCT_IDS.SpaPremium]: 'Premium',
  [PRODUCT_IDS.SpaRetreat]: 'Retreatspa',
  [PRODUCT_IDS.SpaSignature]: 'Signature',
  [PRODUCT_IDS.BLSummerCard]: 'Summer Card',
  [PRODUCT_IDS.BLSummerCardFamily]: 'Summer Card',
  [PRODUCT_IDS.BLWinterCard]: 'Winter Card',
  [PRODUCT_IDS.BLWinterCardFamily]: 'Winter Card',
  [PRODUCT_IDS.SilicaMudMask30ml]: 'Silica Mud Mask',
  [PRODUCT_IDS.SilicaMudMask75ml]: 'Silica Mud Mask',
  [PRODUCT_IDS.SilicaMudMask100ml]: 'Silica Mud Mask',
  [PRODUCT_IDS.Massage30]: 'Relaxing in-water massage (30 min)',
  [PRODUCT_IDS.Massage60]: 'Relaxing in-water massage (60 min)',
  [PRODUCT_IDS.Massage120]: 'Signature in-water massage',
  [PRODUCT_IDS.MassageFloatingOne]: 'Solo Float Therapy',
  [PRODUCT_IDS.MassageFloatingTwo]: 'Couples Float Therapy',
  [PRODUCT_IDS.RestaurantLava]: 'Reserve a table at Lava Restaurant',
  [PRODUCT_IDS.SpaRestaurant]: 'Reserve a table at Spa Restaurant',
  [PRODUCT_IDS.TransferTwoWay]: 'DBL Two way transfer',
  [HB_PRODUCT_IDS.AdultAdmission]: 'Highland Base Baths',
  [HB_PRODUCT_IDS.ChildAdmission]: 'Highland Base Baths',
  [HB_PRODUCT_IDS.TeenAdmission]: 'Highland Base Baths',
  [HB_PRODUCT_IDS.HighlandBaseRestaurant]: 'Highland Base Restaurant',
}

export const productIdToItemId = {
  [PRODUCT_IDS.SpaComfort]: 'comfort',
  [PRODUCT_IDS.SpaPremium]: 'premium',
  [PRODUCT_IDS.SpaRetreat]: 'retreatspa',
  [PRODUCT_IDS.SpaSignature]: 'signature',
  [PRODUCT_IDS.BLSummerCard]: 'subscription',
  [PRODUCT_IDS.BLSummerCardFamily]: 'subscription',
  [PRODUCT_IDS.BLWinterCard]: 'subscription',
  [PRODUCT_IDS.BLWinterCardFamily]: 'subscription',
  [HB_PRODUCT_IDS.AdultAdmission]: 'highlandbasebaths',
  [HB_PRODUCT_IDS.ChildAdmission]: 'highlandbasebaths',
  [HB_PRODUCT_IDS.TeenAdmission]: 'highlandbasebaths',
}

export const productIdToItemListId = {
  [PRODUCT_IDS.SilicaMudMask30ml]: 'extras',
  [PRODUCT_IDS.SilicaMudMask75ml]: 'extras',
  [PRODUCT_IDS.SilicaMudMask100ml]: 'extras',
  [PRODUCT_IDS.Massage30]: 'massage',
  [PRODUCT_IDS.Massage60]: 'massage',
  [PRODUCT_IDS.Massage120]: 'massage',
  [PRODUCT_IDS.MassageFloatingOne]: 'float',
  [PRODUCT_IDS.MassageFloatingTwo]: 'float',
  [PRODUCT_IDS.RestaurantLava]: 'extras',
  [PRODUCT_IDS.SpaRestaurant]: 'extras',
  [HB_PRODUCT_IDS.HighlandBaseRestaurant]: 'extras',
  [HB_PRODUCT_IDS.TransferSkjolHb]: 'extras',
  [HB_PRODUCT_IDS.TransferHbSkjol]: 'extras',
  [HB_PRODUCT_IDS.TransferHbRvk]: 'extras',
  [HB_PRODUCT_IDS.TransferRvkHb]: 'extras',
}

export const productIdToItemListName = {
  [PRODUCT_IDS.SilicaMudMask30ml]: 'Extras',
  [PRODUCT_IDS.SilicaMudMask75ml]: 'Extras',
  [PRODUCT_IDS.SilicaMudMask100ml]: 'Extras',
  [PRODUCT_IDS.Massage30]: 'Massage',
  [PRODUCT_IDS.Massage60]: ' Massage',
  [PRODUCT_IDS.Massage120]: 'Massage',
  [PRODUCT_IDS.MassageFloatingOne]: 'Float',
  [PRODUCT_IDS.MassageFloatingTwo]: 'Float',
  [PRODUCT_IDS.RestaurantLava]: 'Extras',
  [PRODUCT_IDS.SpaRestaurant]: 'Extras',
  [HB_PRODUCT_IDS.HighlandBaseRestaurant]: 'Extras',
  [HB_PRODUCT_IDS.TransferSkjolHb]: 'Extras',
  [HB_PRODUCT_IDS.TransferHbSkjol]: 'Extras',
  [HB_PRODUCT_IDS.TransferHbRvk]: 'Extras',
  [HB_PRODUCT_IDS.TransferRvkHb]: 'Extras',
}

type ProductImpression = {
  event: ViewItemList['event']
  impressions: ViewItemList['ecommerce']['items']
}

const extractProductImpressions = (
  productReferences: ProductReference[],
  iskEurExchangeRate: number
): ViewItemList['ecommerce']['items'] => {
  const result: Record<string, ProductImpression['impressions']> = {}

  productReferences.forEach(productReference => {
    const { productId, brand, category, list, name, price } =
      productReference.fields

    let priceValue = 0
    if (price && price?.fields.isk) {
      priceValue = calcPrice(price.fields.isk, iskEurExchangeRate) ?? 0
    }

    if (result[category] === undefined) {
      result[category] = []
    }

    result[category].push({
      item_id: productId,
      item_brand: brand,
      item_category: category,
      item_list_name: list,
      item_list_id: lodash.snakeCase(list),
      item_name: name,
      price: priceValue,
      index: result[category].length,
      currency: 'EUR',
      quantity: 1,
    })
  })

  return Object.keys(result).flatMap(key => result[key])
}

export const triggerProductImpressions = (
  productReferences: ProductReference[],
  iskEurExchangeRate: number
) => {
  const productImpressions = extractProductImpressions(
    productReferences,
    iskEurExchangeRate
  )

  triggerEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: lodash.snakeCase(productReferences?.[0]?.fields?.list),
      item_list_name: productReferences?.[0]?.fields?.list,
      items: productImpressions,
    },
  })
}

export const triggerProductClick = (
  productReference: ProductReference,
  iskEurExchangeRate: number
) => {
  const {
    productId,
    brand,
    category,
    name,
    price: priceEntry,
    list,
  } = productReference.fields

  const price = get(priceEntry, 'fields.isk', 0)

  triggerEvent({
    event: 'select_item',
    ecommerce: {
      item_list_id: lodash.snakeCase(list),
      item_list_name: list,
      currency: 'EUR',
      items: [
        {
          item_id: productId,
          item_name: name,
          item_brand: brand,
          item_category: category,
          item_list_id: lodash.snakeCase(list),
          item_list_name: list,
          currency: 'EUR',
          price: calcPrice(price, iskEurExchangeRate) ?? 0,
          quantity: 1,
        },
      ],
    },
  })
}
